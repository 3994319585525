
import { Options } from "vue-property-decorator";
import MultiChoice from "@/components/Pages/TheoryQuestions/Parts/MultiChoice.vue";
import FreeText from "@/components/Pages/TheoryQuestions/Parts/FreeText.vue";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import { mixins } from "vue-class-component";

@Options({
  components: { FreeText, MultiChoice },
})
export default class BaseQuestion extends mixins(QuestionMixin) {
  private onAnswer(correct: boolean): void {
    this.$emit("onAnswer", {
      correct: correct,
    });
  }
}
