import { Options, Vue, Watch } from "vue-property-decorator";
import { namespace } from "s-vuex-class";

const TheoryQuestionsModule = namespace("theory-questions");

@Options({
  emits: ["onMarkFavourite"],
})
export default class FavoriteActionMixin extends Vue {
  @TheoryQuestionsModule.Action("markFavourite")
  public markFavouriteAction: any;

  @TheoryQuestionsModule.Getter("getMarkedFavouriteSuccess")
  public markFavouriteSuccess: any;

  private favourite = false;

  protected markFavourite(studentEducationId: number, questionId: number): void {
    this.markFavouriteAction({
      studentEducationId: studentEducationId,
      theoryQuestionId: questionId,
      favourite: !this.favourite,
    });
  }

  @Watch("question", { immediate: true, deep: true })
  private onQuestionChange(question: any): void {
    if (question) {
      this.favourite = question.favourite;
    }
  }

  @Watch("markFavouriteSuccess", { immediate: true, deep: true })
  private onMarkFavouriteSuccess(success: any): void {
    if (true === success) {
      this.favourite = !this.favourite;
      this.$emit("onMarkFavourite", this.favourite);
    }
  }
}
