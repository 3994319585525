
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import emitter from "@/utils/EventBus";
import { IQuestion } from "@/interfaces/IQuestion";
import { isPlatform } from "@ionic/vue";

export default defineComponent({
  name: "QuestionHint",
  props: {
    question: {
      type: Object as PropType<IQuestion>,
    },
  },
  setup(props) {
    const active = ref(false);
    const scrollToBottom = () => {
      scroll("question-hint");
    };

    const scroll = (id: string) => {
      if (!isPlatform("desktop")) {
        document.getElementById(id)?.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    const scrollToTop = () => {
      scroll("question-content");
    };

    const onShow = () => {
      scrollToBottom();
    };

    const onHide = () => {
      scrollToTop();
    };

    onMounted(() => {
      emitter.$on("question-hint-toggle", () => {
        if (questionHint.value) active.value = !active.value;
      });

      emitter.$on("question-hint-model", (value: boolean) => {
        if (questionHint.value) active.value = value;
      });
    });

    const questionHint = computed(() => {
      return props?.question?.hint || null;
    });

    return {
      onShow,
      onHide,
      active,
      questionHint,
    };
  },
});
