
import { Options, Prop, Vue } from "vue-property-decorator";
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonMenuButton, IonButton } from "@ionic/vue";
import DriveBuzzLogo from "@/components/DriveBuzzLogo.vue";

@Options({
  components: {
    DriveBuzzLogo,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonMenuButton,
    IonButton,
  },
})
export default class ForwardHeader extends Vue {
  public name = "ForwardHeader";

  @Prop({ type: String })
  public defaultHref!: string;

  @Prop({ type: Boolean, default: () => false })
  public hideLogo!: boolean;

  public onClickBack(): void {
    this.$router.go(-1);
  }
}
