
import { defineComponent } from "vue";

export default defineComponent({
  name: "BackButton",
  props: {
    cols: {
      default: () => 4,
    },
    className: {
      default: () => "d-flex",
    },
  },
});
