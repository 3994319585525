
import { Options, Vue, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import Questions from "@/components/Pages/TheoryQuestions/Questions.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: {
    ForwardHeader,
    Questions,
    DefaultLayout,
  },
})
export default class TheoryQuestions extends Vue {
  private studentEducation: number | null = null;

  @Watch("$route", { immediate: true, deep: true })
  private onRouteChange(newVal: any): void {
    this.studentEducation = newVal.params.studentEducation;
  }
}
